.section{
    min-height: 80vh;
    padding: 5rem;
    position: relative;
    
    /* transition: 150ms;
    opacity: 0;
    transform: translateX(100px); */
}

.section:has(.project){
    padding: 0;
}

.section__header{
    margin: 0 10rem;
}
.show{
  opacity: 1 ;
  transform: translateX(0) ;
}

@media screen and (max-width: 900px){
    .section{
        padding: 0rem;
       }

       .section__header{
        margin: 4rem;
    }
}
