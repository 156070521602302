

.TextSlide{
  max-width: 100vw;
}

.TextSlide>p{
    font-size: 500%;
}


.TextSlide_parent {
  margin-top: 15vh;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  section div {
    position: relative;
  }

  @media screen and (max-width: 900px){
    .TextSlide>p{
      font-size: 300%;
      margin: 2rem
  }
  }
  

