.allContact{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-wrap: wrap;
}

.mail{
         margin: 5rem 10rem;
         min-height: 10rem;
         position: relative;
         display: flex;
         justify-content: center;
         flex-direction: column;
}

.mail__p{
         height: auto;
         position: relative;
         margin-bottom: 4rem;
}



.mail__li{
         color: var(--fontMainColor);
}

.mail__a{
         background-color: #3f3f3f;
         margin-top: 2rem;
         margin: 0 auto;
         padding: 1.5rem;
         position: relative;
         font-size: 150%;
         text-align: center;
         box-shadow: 0 0 2rem rgba(0,0,0,0.5);
         border-radius: 1.5rem;
}

.contact{
         margin: 5rem 10rem; 
         text-align: center;
        
}

.contact__ul{
         margin-top: 2rem;
         display: flex;
         flex-direction: column;
         gap: 1rem;
}

@media screen and (max-width: 900px) {
         .mail__a{
                  width: 20rem;
         }
}