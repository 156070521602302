.project__paragraph{
    border-left: 3px solid grey;
    padding: 1rem;
    width: 100%;
    max-width: 100vw;
}

.project__img{
    width: 60rem;
    margin: 1rem 0;
}

.project__ul{
    margin: 1rem;
    display: flex;
    gap: 1rem;
}

.project__li{
    color: var(--fontSecondaryColor);
    list-style-type: none;
}

@media screen and (max-width: 1100px){
   
}

@media screen and (max-width: 700px){
    .project__img{
        width: 55rem;
    }
}

@media screen and (max-width: 600px){

    .project{
        width: 100vw;
    }
    .project__wrapper{
        margin: 4rem;
    }
    .project__img{
        width: 100%;
        border-radius: 0;
    }

    .project__paragraph{
        border: none;
        width: 100%;
    }
}

