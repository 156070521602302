.experience{
    width: 10wh;
    display: flex;
    flex-direction: column ;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.experience__total{

    padding: 0 0rem 0 10rem;
}

.experience__total h2{
    font-size: 6rem;
    border-bottom: var(--border) 2px solid;
    width: fit-content;

}

.experience__total-p{
    
    width: 70%;
}

.ma{
    color: #FF00E6
}

.experience__under{
    background-color: var(--secondaryBackground);
    color: white;
    padding: 1rem;
}

.experience__allPart{
    /* background-color: #3f3f3f; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    transition: all 0.5s;
    margin: 0 10rem;
    padding: 1rem;
    /* max-height: 80rem;
    overflow-y: scroll; */
}


@media screen and (max-width: 900px){
    .experience__total{

        padding: 4rem;
    }

    .experience__allPart{
        padding: 0;
        margin: 4rem;
    }

    .experience__total-p{
        width: 100%;
    }
}

