.projectsNav{
    min-width: 20vw;
    padding: 8rem;
    
}

.projectsNav__ul{
    margin-top: 2rem;
    padding: 2rem;
    /* border-top: var(--border) 3px solid;
    border-left: var(--border) 3px solid; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}





.projectLink{
    cursor: pointer;
}


.projectsNav__ul li {
    display: inline-block;
    position: relative;
    color: var(--specialColor);
  }
  
  .projectsNav__ul li:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--specialColor);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .projectsNav__ul li:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: 1100px){
    .projectsNav{
      margin-left: 6rem
    }
  }

  @media screen and (max-width: 900px){
    .projectsNav{
      margin-left: 0;
      padding: 0;
      margin-left: 4rem;
    }
  }

  .projectNav{
    max-height: 30rem;
  }
