.experience__part{
         background-color: var(--secondaryBackground);
         box-shadow: 0 0 2rem rgba(0,0,0,0.5);
         width: 20vh;
         height: 15rem;
         color: #f2f2f2;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         transition: all 0.5s;
         padding: 2rem; 

     }


.experience__part div{
    display: flex;
   gap: 1rem;
   flex-direction: column;
  justify-content: center;
  align-items: center;
}

.experience__part-explain{
    justify-content: flex-start;
    transition: all 0.5s;
    
}

.experience__figure{
    font-size: 4rem;
}


.experience__part-h3{
         font-size: 2rem;
         font-weight: bold;
         font-family: sans-serif;
}

.experience__part-subject{
    font-size: 100%;
}
