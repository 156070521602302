.info{
    width: 40vw;
    height: auto;
    padding: 0rem 0rem 4rem 0rem;
    z-index: 1;
    margin: 8rem;
}

/* .img-wrapper{
    width: 20vh;
    height: 20vh;
    
    position: absolute;
} */


@media screen and (max-width: 1100px) {
    .info{
        margin-left: 12rem
    }
}
@media screen and (max-width: 900px) {
    .info{
        width: auto;
        margin: 0rem;
    }
}