.project{
         min-height: 100vh;
         max-width: 100vw;
         display: flex;
         flex-direction: row;
         gap: 4rem;
         padding: 0rem 10rem ;
         margin: 4rem;
     }
     
     
     @media screen and (max-width: 1100px) {
        .project{
         flex-direction: column;
         padding: 4rem;
         margin: 0;
         width: 100%;
         gap: 0;

        }
       }
       @media screen and (max-width: 600px) {
.project{
    padding: 0;
}
       }
